@page {
  size: auto;
}
.bookingGrid {
  overflow: hidden !important;
}

.totalsSpans {
  padding-left: 50px;
}

.totalsSpansMobile {
  padding-left: 0px;
}

.totalsSpansNumber {
  font-weight: bold;
}

.ag-header-cell-label {
  justify-content: center;
}

.ag-row-selected {
  border-bottom: 1px solid !important;
  border-bottom-color: rgba(33,150,243,0.3) !important;
}

.booking-grid-arrived {
  background-color: #A8DF8E !important;
  border-bottom: 1px solid !important;
  border-bottom-color: #A8DF8E !important;
}

.table tr {
  border: black solid 3px !important;
  table-layout: fixed;
}

.commitText {
  text-decoration-color: blue !important;
}

tr th {
  height: 50px;
}

tr td {
  height: 100px;
  vertical-align: center;
}

td {
  display: table-cell;
  vertical-align: middle;
}


main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
}

html {
  font-size: 14px;
}

@media (min-width: 768px) {
  html {
      font-size: 16px;
  }
}

html {
  height: -webkit-fill-available;
  position: relative;
  min-height: 100%;
}

body {
  min-height: 100vh !important;
  min-height: -webkit-fill-available;
}

/* Nav Styling */
.nav-flush .nav-link {
  border-radius: 0;
}

.active {
  background-color: #118AB2 !important;
}

li.nav-item:hover {
  color: rgba(0, 0, 0, .85);
  background-color: #073B4C;
  border-radius: 25px;
}

.nav-title-separator {
  width: 60%;
  margin-left: 20%;
  color: #FFD166 !important;
  opacity: .5;
}

.nav-title {
  /*text-align: left;
  padding-left: 20px;*/
  font-size: larger;
  border-radius: 15px;
  background: linear-gradient(35deg,#073b4c, #ef476f);
}

.mainContent {
  padding-left: 1rem;
  flex-grow: 1;
  flex-shrink: 0;
  width: calc(100vw - 280px);
}

.mainPage {
  flex-grow: 1;
}


/* Mobile Menu */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(33, 37, 41);
  padding: 0.25em;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}